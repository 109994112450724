#video_module.cms_list {
	.cms_list_item {
		border-bottom: 1px solid #ccc;
		padding-bottom: 1.5em;
		margin-bottom: 1.5em;

		table {
			td {
				vertical-align: top;
			}
		}

		.cms_title {
			margin-bottom: 5px;

			h3 {
				margin-bottom: 0;
				margin-top: 0;
			}
		}

		.cms_date {
			margin-bottom: 5px;
		}

		&:last-of-type {
			border-bottom: 0 none;
			padding-bottom: 0;
			margin-bottom: 0;
		}

		.cms_video {
			width: 140px;
			padding-right: 20px;

			.responsive {
				width: 100%;
				height: auto;
			}

			.padded {
				padding: 10px 10px 0 0;
			}
		}

		.cms_video_featured {
			width: 130px;
		}
	}
}

#video_module.cms_entity {
	.cms_date {
		margin-bottom: 5px;

		h3 {
			margin: 10px 0 0;
		}
	}

	.cms_content {
		margin-bottom: 5px;

		p {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.cms_footer {
		clear: both;
		padding-top: 10px;
	}

	.cms_media_container {
		position: relative;

		.cms_media_thumbnail {
			left: 0px;
			position: absolute;
			top: 0px;
			z-index: 2;
		}

		.cms_media_player {
			left: 0px;
			position: absolute;
			top: 0px;
			z-index: 1;
		}
	}
}

.homepage-featured-video {
	.videoEmbedLink {
		background: #000000;
		text-align: center;
		display: block;
		float: none;
	}
}

#video_module.cms_list_carousel {
	* {
		box-sizing: border-box;
	}

	h2 {
		margin-top: 0px;
	}

	.subcategries-container {
		display: inline-block;
		width: 100%;
	}

	.category-container {
		border-bottom: 1px solid #eee;
		margin-bottom: 1.5rem;
		padding-bottom: 1.5rem;
		display: inline-block;
		width: 100%;
	}

	.video-container {
		position: relative;

		.owl-carousel {
			width: calc(100% - 60px);
			margin: 10px auto;

			.owl-nav {
				.owl-prev {
					position: absolute;
					display: block;
					height: 30px;
					width: 30px;
					cursor: pointer;
					left: auto;
					right: auto;
					top: 40%;
					bottom: auto;
					left: -30px;

					span {
						display: block;
						height: 30px;
						width: 30px;
						background-repeat: no-repeat;
						background-size: 20px 20px;
						background-position: center;
						text-indent: -9999px;
						z-index: -1;
						background-image: url("/core/website/modules/videos/images/ic-prev.png");
					}
				}

				.owl-next {
					position: absolute;
					display: block;
					height: 30px;
					width: 30px;
					cursor: pointer;
					left: auto;
					right: auto;
					top: 40%;
					bottom: auto;
					right: -30px;

					span {
						display: block;
						height: 30px;
						width: 30px;
						background-repeat: no-repeat;
						background-size: 20px 20px;
						background-position: center;
						text-indent: -9999px;
						z-index: -1;
						background-image: url("/core/website/modules/videos/images/ic-next.png");
					}
				}
			}

			.owl-dots {
				text-align: center;
				-webkit-tap-highlight-color: transparent;
				margin-top: 0.75rem;

				.owl-dot {
					display: inline-block;
					zoom: 1;

					span {
						width: 10px;
						height: 10px;
						margin: 5px 7px;
						background: #d6d6d6;
						display: block;
						-webkit-backface-visibility: visible;
						transition: opacity 0.2s ease;
						border-radius: 30px;
					}

					&.active span,
					&:hover span {
						background: #363636;
					}
				}
			}
		}

		.video-title {
			text-align: center;
		}

		.video-file-youtube {
			background-color: #000;
			margin-bottom: 15px;
			position: relative;
			padding-top: 56.25%;
			overflow: hidden;
			cursor: pointer;
			color: #fff;

			img {
				width: 100%;
				top: 0px;
				left: 0;
				height: 100%;
				opacity: 0.7;
				cursor: pointer;
				position: absolute;
			}

			.play-button {
				width: 90px;
				height: 60px;
				background-color: #333;
				box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
				z-index: 1;
				opacity: 0.8;
				border-radius: 6px;
				cursor: pointer;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);

				&:before {
					content: "";
					border-style: solid;
					border-width: 15px 0 15px 26px;
					border-color: transparent transparent transparent #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate3d(-50%, -50%, 0);
				}
			}

			iframe {
				position: absolute;
				height: 100% !important;
				width: 100% !important;
				top: 0;
				left: 0;
				float: none !important;
			}
		}

		.video-file {
			background-color: #000;
			margin-bottom: 15px;
			position: relative;
			padding-top: 56.25%;
			overflow: hidden;
			cursor: pointer;
			color: #fff;

			video {
				position: absolute;
				height: 100% !important;
				width: 100% !important;
				top: 0;
				left: 0;
				float: none !important;
			}

			.cms_media_container {
				position: absolute;
				height: 100% !important;
				width: 100% !important;
				top: 0;
				left: 0;
				float: none !important;

				.cms_media_thumbnail {
					width: 100% !important;
					height: 100% !important;
					border: 0px;
					position: relative;
					overflow: hidden;

					img {
						width: auto !important;
						height: 100% !important;
						margin: 0 auto;
						position: relative;
					}
				}

				.cms_media_player {
					width: 100% !important;
					height: 100% !important;
					border: 0px;
					position: relative;
					overflow: hidden;

					object {
						width: auto !important;
						height: 100% !important;
						margin: 0 auto;
						position: relative;
					}
				}
			}
		}
	}
}

@media (-ms-high-contrast: none) {
	#video_module.cms_list_carousel {
		.video-container {
			.owl-carousel {
				.owl-stage {
					.owl-item {
						visibility: hidden;
					}

					.owl-item.active {
						visibility: visible;
					}
				}
			}
		}
	}
}
